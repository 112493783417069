.settings-account {
  .card {
    height: auto;
    .card-body {
      .collapse-list {
        border-bottom: 2px solid rgb(222, 226, 230);
        div {
          padding: 10px 20px;
          margin: 0;
          font-size: 13px;
          cursor: pointer;
          color: rgb(100, 103, 119);
          text-transform: uppercase;
          font-weight: 500;
          line-height: 20px;

          &:hover {
            outline: none;
            color: rgb(100, 103, 119);
            background-color: rgb(250, 251, 254);
          }

          @media (max-width: 768px) {
            font-size: 13px;
            padding: 10px 15px;
          }
        }
        .active {
          border-bottom: 2px solid rgb(76, 225, 182);
        }
      }
    }
  }
  .wrapper-general-cards {
    display: flex;
    padding-bottom: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    div {
      width: 100%;
      background: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 25px;
      color: #646777;
      &:first-child {
        border-left: 2px solid #FFC53A;
        margin-right: 10px;
      }
      &:last-child {
        border-left: 2px solid #49a666;
        margin-left: 10px;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }
  }
}
.user-photo-container {
  align-items: center;
  margin: 0 auto;
  border: 2px dashed #efefef;
  border-radius: 5px;
  padding: 35px 10px;
  width: 100%;

  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 768px) {
    width: 75%;
  }
  img {
    max-width: 120px;
    max-height: 120px;
    margin: 30px 0;
  }
  button {
    margin: 0 15px;
  }
}